import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/global/Layout";
import SectionParagraph from "../../components/ui/SectionParagraph";
import SectionTitle from "../../components/ui/SectionTitle";

const OdpadyMetaliKolorowychPage = () => {
  return (
    <Layout
      titleTemplate="%s"
      pageTitle="Skup metali kolorowych - miedzi, aluminium, ołowiu, brązu"
      pageDescription="Nasz zakład zajmuje się skupem metali kolorowych między innymi miedzi, aluminium, ołowiu cyny i metali niezawierających żelaza."
    >
      <section className="container mx-auto px-5 py-16">
        <div className="flex flex-row gap-6 justify-between items-center flex-wrap lg:flex-nowrap space-x-8">
          <div className="md:w-1/2">
            <StaticImage
              src="../../images/services/metale-kolorowe.jpg"
              alt="Odpady metali kolorowych"
              width={800}
              height={600}
            />
          </div>
          <div className="md:w-1/2">
            <SectionTitle size="small">Odpady metali kolorowych</SectionTitle>
            <div className="flex flex-row gap-6 justify-between flex-wrap lg:flex-nowrap">
              <div className="md:w-1/2">
                <SectionParagraph className="mb-5">Skupujemy:</SectionParagraph>
                <ul className="list-disc text-xl text-gray-700 pl-5">
                  <li>miedź</li>
                  <li>brąz</li>
                  <li>mosiądz</li>
                  <li>aluminium</li>
                  <li>ołów</li>
                  <li>cyna</li>
                  <li>pasta cynowa</li>
                  <li>cynk</li>
                  <li>stal nierdzewna</li>
                </ul>
              </div>
              <div className="md:w-1/2">
                <SectionParagraph className="mb-5">
                  oraz metale rzadkie:
                </SectionParagraph>
                <ul className="list-disc text-xl text-gray-700 pl-5">
                  <li>ind</li>
                  <li>tantal</li>
                  <li>molibden</li>
                  <li>wolfram</li>
                  <li>wanad</li>
                  <li>kadm</li>
                  <li>tytan</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OdpadyMetaliKolorowychPage;
